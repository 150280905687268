<template>
	<div class="loginbg">

		<div class="bgvideo" style="background-image: url(https://img-baofun.zhhainiao.com/pcwallpaper_ugc/live/598a71bab9006c462a70423cfc04611a.mp4.jpg);">
			
		</div>
		<div class="login_box">
			<div style="width: 150px; margin: 0 auto;">
				<img :src="'./images/logo2.png'"  style="width: 100%;"/>
			</div>
			<div id="platformname"
				style="text-align: center; padding-bottom: 20px; font-size: 24px; font-weight: bold;">欢迎登陆</div>
			<!-- <div class="coricon" @click="changeLoginType">
				<img v-if="!showQrcode" :src="'./images/icon-qrcode.png'" alt="">
				<img v-else :src="'./images/icon-pwd.png'" alt="">
			</div> -->
			<div v-if="!showQrcode">


				<el-form :model="EditItem">

					<el-form-item>

						<el-input placeholder="请输入账号" @keyup.enter.native="login" v-model="username">
							<template slot="prepend">账 号</template>
						</el-input>

					</el-form-item>
					<el-form-item>

						<el-input type="password" placeholder="请输入密码" @keyup.enter.native="login" v-model="password">
							<template slot="prepend">密 码</template>
						</el-input>

					</el-form-item>


					<div style="height: 20px; text-align: center; padding-top: 50px;">
						<el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
					</div>
				</el-form>

			</div>

			<div v-else>
				<div style="width: 60%; margin: 0 auto; padding-top: 30px;">
					<img :src="qrcodeUrl?qrcodeUrl:'../../images/qrcode.jpg'" alt="" style="width: 100%;">
				</div>

			</div>



		</div>

		<el-dialog title="初次登录请先修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<div style="text-align: center; color: orangered; padding-bottom: 20px;">密码为长度8-16位的字符、数字和特殊字符的组合</div>
			<el-form :model="pwdForm">

				<el-form-item>

					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">修改密码</span></template>
					</el-input>

				</el-form-item>

				<el-form-item>

					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">确认密码</span></template>
					</el-input>

				</el-form-item>


				<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
					<el-button :disabled="showbtn" type="primary" style="width: 100%;" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShowModal: false,
				showbtn: false,
				bgidx: "",
				bgvideourl: "",
				EditItem: {},
				username: "",
				password: "",
				session_id: "",
				yzmUrl: "",
				yzm: "",
				showQrcode: false,
				qrcodeUrl: "",
				qrcodeChecking: false,
				qrcodeTimer: -1,
				pwdForm: {
					newPwd1: "",
					newPwd2: "",

				}
			}
		},
		mounted() {


			this.getBgVideo()


		},
		methods: {
			getBgVideo() {

				this.$http.post("https://api-sch-saas.aiwx.org.cn/api/sch_weather", {
					pmid: 1
				}).then(res => {
					this.bgvideourl = res.data.theme.video_url
					this.bgidx = res.data.theme.img_url

				})
				this.getConfig()
			},
			getConfig() {
				let pmid = 2;
				if (window.location.href.indexOf("mymedia") > 0) {
					pmid = 3
				}
				this.$http.post("/api/platform_configs", {
					pmid: pmid
				}).then(res => {
					for (let item of res.data) {
						if (item.ktype == 'left_logo') {
							this.logo2 = item.kvalue;
						}
						if (item.ktype == 'school_name') {
							document.title = "欢迎登录" + item.kvalue
							$("#platformname").text(item.kvalue)
						}
						//首页图标
						if (item.ktype == 'favicon' || item.ktype == 'favico') {
							var link = document.createElement('link');
							link.type = 'image/x-icon';
							link.rel = 'shortcut icon';
							link.href = item.kvalue;
							document.getElementsByTagName('head')[0].appendChild(link);
						}
					}
				})

			},
			getQrcode() {
				this.$http.post("/api/login_qrcode", {
					pmid: window.pmid
				}).then(res => {

					this.session_id = res.data.session;

					this.qrcodeUrl = res.data.url;
					if (!this.qrcodeChecking) {
						this.qrcodeChecking = true

						this.checkQrcode();
					}
				})



			},
			checkQrcode() {

				this.qrcodeTimer = setInterval(() => {

					this.$http.post("/api/check_qrcode", {
						session_id: this.session_id,
						noloading: 1,
						pmid: window.pmid
					}).then(res => {
						if (res.data.token) {
							if (res.data.pmid != window.pmid) {
								this.$message({
									message: "错误的二维码",
									type: 'error',
									offset: '600'
								});
							} else {
								let menus = res.data.menus;
								delete res.data.menus
								sessionStorage.setItem("userinfo", JSON.stringify(res.data))
								if (!localStorage.getItem("year")) {
									localStorage.setItem("year", new Date().getFullYear())
								}
								sessionStorage.setItem("menus", menus)
								this.getMenus()
								clearInterval(this.qrcodeTimer)
								this.$router.push("/home")
							}

						}
					})
				}, 1000)
			},
			getYZM() {
				this.$http.post("/api/get_login_randomcode", {
					pmid: window.pmid
				}).then(res => {
					this.session_id = res.data.data.session_id;
					this.yzmUrl = res.data.data.svg;
				})


			},
			changeYzm() {
				this.getYZM()
			},
			login() {
				if (this.username.trim() && this.password.trim()) {
					this.$http.post("/api/admin_login", {
						phone: this.username,
						pwd: this.password
					}).then(res => {
						if (res.data && res.data.token) {

							sessionStorage.setItem("userinfo", JSON.stringify(res.data))
							this.$router.push("/home")


						} else {
							this.$message({
								message: res.data.msg,
								type: 'error',
								offset: '600'
							});

						}


					}, err => {

					})
				} else {
					this.$message({
						message: "好像没有填完整~",
						type: 'error',
						offset: '600'
					});
				}
			},
			changeLoginType() {
				this.showQrcode = !this.showQrcode
				if (this.showQrcode && !this.qrcodeUrl) {
					this.getQrcode()
				}
			},

			checkPassWord(value) {
				if (value.length < 8 || value.length > 16) { //最初级别
					this.$message.error("密码长度为8-16位")
					return false;
				}
				if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
					this.$message.error("密码必须包含数字")
					return false;
				}
				if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
					this.$message.error("密码必须包含大小写字母")
					return false;
				}

				var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
					regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

				if (!regEn.test(value) && !regCn.test(value)) {
					this.$message.error("密码必须包含特殊字符")
					return false;
				}

				return true


			},

			savePwd() {

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码")
					return
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码")
					return
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致")
					return
				}
				if (this.checkPassWord(this.pwdForm.newPwd2)) {
					this.showbtn = true;
					this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
						if (ok.data) {
							this.$message.success("修改成功,即将跳转")
							setTimeout(() => {
								this.$router.push("/home")
							}, 3000)

						}
					})
				}


			},
		}
	}
</script>

<style scoped>
	.loginbg {
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	.login_box {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -180px;
		margin-top: -270px;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color: #fff;
		width: 320px;
		height: 480px;
		padding: 20px;
		box-shadow: 0 2px 10px #999;
		-moz-box-shadow: #999 0 2px 10px;
		-webkit-box-shadow: #999 0 2px 10px;
	}

	.coricon {
		position: absolute;
		top: 0;
		right: 0;
		;
		width: 60px;
		height: 60px;
	}

	.coricon img {
		width: 100%;
		transform: scale(0.5, 0.5);
	}

	.bgvideo {
		position: fixed;
		width: 100%;
		height: 100%;
		top: -10;
		left: 0;
		z-index: -100;
		background-size: 100% 100%;
	}
</style>
