<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {}
		},
		mounted() {
			let pmid = 2;
			this.$http.post("/api/platform_configs", {
				pmid: pmid
			}).then(res => {
				for(let item of res.data){
					
					if (item.ktype == 'school_name') {
						document.title = "欢迎登录" + item.kvalue + "考试系统"
					}
					//首页图标
					if (item.ktype == 'favicon' || item.ktype == 'favico') {
						var link = document.createElement('link');
						link.type = 'image/x-icon';
						link.rel = 'shortcut icon';
						link.href = item.kvalue;
						document.getElementsByTagName('head')[0].appendChild(link);
					}
					
				}
			})

		},
	}
</script>



<style lang="less">
	html,
	body {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		height: 100%;
		width: 100%;
	}

	.page {
		width: 100%;
		height: 100%;
		background-color: #fff;
	}

	.cbox {
		padding: 20px;
	}

	.nav {
		padding: 10px 0 18px 0;
		border-bottom: 1px solid #f6f6f6;
		margin-bottom: 20px;
		position: relative;
	}

	p {
		margin-top: 2px !important;
		margin-bottom: 2px !important;
	}

	.rowlb {
		padding: 10px 15px;
		font-size: 14px;
		line-height: 14px;
		border-bottom: 1px solid #F6F6F6;
		background-color: #fff;
		font-weight: bolder;
	}

	.rowlb span {
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #00A0E9;
		vertical-align: middle;
		transform: translateY(-1px);

	}

	.datatable {
		background-color: #E4E7ED;
		width: 100%;
	}

	.datatable td {
		background-color: #fff;
		text-align: center;
		padding: 10px 0;
	}

	.viewer-container {
		z-index: 99999999 !important;
	}

	.dtable,
	.dtable tr th,
	.dtable tr td {
		border: 1px solid #000;
	}

	.dtable {
		width: 100%;
		min-height: 25px;
		line-height: 25px;
		text-align: center;
		border-collapse: collapse;
		padding: 2px;
	}
</style>
<style media="print">
	@page {
		size: auto;
		/* auto is the initial value */
		margin: 0mm;
		/* this affects the margin in the printer settings */
	}
</style>
