import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pmid:window.pmid,
		teacherRole:{}
	},
	mutations: {
		setTeacherRole(state,value){
			state.teacherRole = value
		},
		setPmid(state,value){
			state.pmid = value
		},
	},
	actions: {
		setTeacherRole(context,value){
			context.commit("setTeacherRole",value);
		},
		setPmid(context,value){
			context.commit("setPmid",value);
		}
	},
	modules: {}
})
