import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
// import index from '../views/index.vue'
Vue.use(VueRouter)



let routes = [{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
			path: '/online',
			name: 'online',
			component: resolve => require(['../views/pcexam/online'], resolve)
		},
	{
		path: '*',
		name: '404',
		component: resolve => require(['../views/404'], resolve)
	},
	
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/index',
		name: 'index',
		component: resolve => require(['../views/index'], resolve),
		children: [{
			path: '/home',
			component: resolve => require(['../views/home/home'], resolve)
		},{
			path: '/syslog',
			component: resolve => require(['../views/sys/syslog'], resolve)
		},{
			path: '/setting',
			component: resolve => require(['../views/sys/sch_params'], resolve)
		},{
			path: '/teacher',
			component: resolve => require(['../views/sys/teacher'], resolve)
		},{
			path: '/tiku_tm',
			component: resolve => require(['../views/tk_timu/tiku_tm'], resolve)
		},{
			path: '/exam_moni',
			component: resolve => require(['../views/tk_exam/list'], resolve)
		},{
			path: '/exams',
			component: resolve => require(['../views/tk_exam/list_zs'], resolve)
		},{
			path: '/course',
			component: resolve => require(['../views/sys/course'], resolve)
		},{
			path: '/students',
			component: resolve => require(['../views/sys/students'], resolve)
		}]
	},
	
	
]


let router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router
