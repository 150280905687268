let digitUppercase = function(n) {
    var fraction = ['角', '分'];
    var digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖'
    ];
    var unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟']
    ];
    var head = n < 0 ? '欠' : '';
    n = Math.abs(n);
    var s = '';
    for (var i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
    }
    s = s || '整';
    n = Math.floor(n);
    for (var i = 0; i < unit[0].length && n > 0; i++) {
        var p = '';
        for (var j = 0; j < unit[1].length && n > 0; j++) {
            p = digit[n % 10] + unit[1][j] + p;
            n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    }
    return head + s.replace(/(零.)*零元/, '元')
        .replace(/(零.)+/g, '零')
        .replace(/^整$/, '零元整');
}

let asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

let get4Num=(n)=>{
	let m=String(n);
	let l = m.length;
	for(let i=0;i<4-l;i++){
		m='0'+m
	}
	return m
}

let get3Num=(n)=>{
	let m=String(n);
	let l = m.length;
	for(let i=0;i<3-l;i++){
		m='0'+m
	}
	return m
}


let getCity=(n)=>{
	let c = "";
	if(n){
		let arr = n.split(",")
		if(arr.length>=3){
			c=arr[1]
		}else if(arr.length>=2){
			c=arr[1]
		}else{
			c = n
		}
	}
	return c
}
import axios from "axios"
let checkUrlSign = wx=>{
	axios.post("/api/get_url_wxsign", {
		url: window.location.href
	}).then(res => {
		let checksign = opt => {
			if (wx && wx.ready) {
				wx.config({
					debug: false,
					appId: res.data.appId,
					timestamp: res.data.timestamp,
					nonceStr: res.data.nonceStr,
					signature: res.data.signature,
					jsApiList: ["uploadImage", "previewImage","chooseImage"]
				});
				
				return 1
			}else{
				return 0
			}
		}
		let t = setInterval(()=>{
			if(checksign()){
				clearInterval(t)
			}else{
				console.log("验证中")
			}
		},200)
		
	})
}

let getDateStr = d=>{
	if(d){
		d = new Date(d)
	}
	let year = d.getFullYear();
	let month = d.getMonth()+1;
	let day = d.getDate();
	month < 10? month='0'+month:true
	day < 10? day='0'+day:true
	
	return year+"-"+month+"-"+day
	
	
}

let getDateTimeStr = d=>{
	if(d){
		d = new Date(d)
	}else{
		d=new Date();
	}
	let year = d.getFullYear();
	let month = d.getMonth()+1;
	let day = d.getDate();
	let h = d.getHours();
	let m = d.getMinutes()
	
	month < 10? month='0'+month:true
	day < 10? day='0'+day:true
	h < 10? h='0'+h:true
	m < 10? m='0'+m:true
	
	return year+"-"+month+"-"+day+" "+h+":"+m+":00"
	
	
}


let getOrderList = idx => {
	let data = {
		0: "A",
		1: "B",
		2: "C",
		3: "D",
		4: "E",
		5: "F",
		6: "G",
		7: "H",
		8: "I",
		9: "J"
	}
	return data[idx]

}

export default {
	digitUppercase,
    asyncLocalStorage,
	get4Num,
	get3Num,
	getCity,
	checkUrlSign,
	getDateStr,
	getDateTimeStr,
	getOrderList
	
}
