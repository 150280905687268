import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import aes from './utils/aes.js'
import util from './utils/util.js'


const isDebug_mode = process.env.NODE_ENV !== 'production';
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

if(!isDebug_mode){
	//线上屏蔽console.log
	window.console.log=function(msg){}
}

import './assets/global.css' // global css
import axios from "axios"
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import echarts from "echarts"
Vue.prototype.$echarts = echarts



//图片查看器
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
 Vue.use(Viewer)



let APIURL = "//examshapi.mmteck.cn";
if(window.location.href.indexOf("localhost")>0){
	//APIURL = "//127.0.0.1:10040";
}
axios.interceptors.request.use(async req => {

	if (!req.data) {
		req.data = {}
	}
	let year = localStorage.getItem("year");
	if (year) {
		req.data.year = year
	}
	
	if (sessionStorage.getItem("userinfo")) {
		let user = JSON.parse(sessionStorage.getItem("userinfo"));
		if (user.token) {
			req.headers["Authorization"] = user.token
		}

	}
	if (req.url.indexOf("http") >= 0) {

	} else {
		if (window.location.href.indexOf("localhost1") > 0) {
			req.url = "//127.0.0.1:10010" + req.url
		} else {
			req.url = "//" + APIURL + req.url
		}
	}
	if (!req.data.noloading) {
		$("#loading").show();
	}

	return req
}, error => {
	return Promise.reject(error)
});
axios.interceptors.response.use(response => {
	$("#loading").hide();
	if (response.data.code == 401) {
		localStorage.removeItem('token')
		router.push('/login')
	} else {
		if (response.data.code && response.data.code != 200) {
			$("#errdg").html(response.data.msg).show();
			setTimeout(() => {
				$("#errdg").hide()
			}, 3000)
			return Promise.reject(null)
		} else {
			return response
		}

	}
}, error => {
	if (error&&error.response&&error.response.status == 401) {
		router.push('/login')
	} else {
		return Promise.reject(error)
	}

})







Vue.prototype.uploadTemp = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}



Vue.prototype.uploadOss = (fileObj) => {
	$("#loading").show();
	setTimeout(function (){
		$("#loading").hide();
	},2000)
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",
	
	};
	var host = 'https://myexam-saas.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}




let getMenus = () => {
	let menus = sessionStorage.getItem("menus")
	if (menus) {

		menus = JSON.parse(aes.decrypt(menus))

		let arr = []
		for (let item of menus) {
			if (item.path) {
				for (let m of router.authRouters) {
					if (m.path == item.path) {
						arr.push(m);
						break;
					}
				}
			}
			if (item.children) {
				for (let sitem of item.children) {
					for (let m of router.authRouters) {
						if (m.path == sitem.path) {
							arr.push(m);
							break;
						}
					}
				}
			}
		}
		let indexpage = {
			path: '/index',
			name: 'index',
			component: resolve => require(['./views/index'], resolve),
			children: [{
				path: '/home',
				component: resolve => require(['./views/home/home'], resolve)
			}]
		}
		indexpage.children = indexpage.children.concat(arr)
		router.addRoute(indexpage)

	} else {
		//window.location.href="/login"
	}

}

Vue.prototype.getMenus = getMenus

getMenus()
//加载其他方法
for (let k in util) {
	Vue.prototype[k] = util[k]
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
